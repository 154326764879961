// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
// ** Redux Imports & Persist
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/storeConfig/store";
// ** Apollo
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { from } from "apollo-link";
// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";
// ** Spinner (Splash Screen)
import Spinner from "@webproducer/vuexy-react/lib/@core/components/spinner/Fallback-spinner";
// ** Ripple Button
import "@webproducer/vuexy-react/lib/@core/components/ripple-button";
// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
// ** React Toastify
import "@webproducer/vuexy-react/lib/@core/scss/react/libs/toastify/toastify.scss";
// ** Core styles
import "@webproducer/vuexy-react/lib/@core/assets/fonts/feather/iconfont.css";
import "@webproducer/vuexy-react/lib/@core/scss/core.scss";
import "./assets/scss/style.scss";
//
import logo from "./assets/images/logo/logo.png";
// ** Service Worker
import * as serviceWorker from "./serviceWorker";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message }) => console.error("ERROR: ", message));
    }

    if (networkError) {
        console.error("ERROR: ", networkError.message);
    }
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, httpLink]),
    defaultOptions: {
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
        mutate: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Spinner />} persistor={persistor}>
            <ApolloProvider client={client}>
                <Suspense fallback={<Spinner logo={logo} />}>
                    <ThemeContext>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                    </ThemeContext>
                </Suspense>
            </ApolloProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
