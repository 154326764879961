import { Settings } from "../../../interfaces";
import { settingsConfig } from "../../../configs/settingsConfig";

export const SET_TIMEZONE = "SET_TIMEZONE";
export const SET_GRAPH_VIEW = "SET_GRAPH_VIEW";

interface Action {
    type: string;
    payload: Settings | null;
}

export const settingsReducer = (state: Settings = { timezone: settingsConfig.timezones[0].value, graphView: false }, action: Action) => {
    switch (action.type) {
        case SET_TIMEZONE: {
            return { ...state, ...action.payload };
        }
        case SET_GRAPH_VIEW: {
            return { ...state, ...action.payload };
        }
        default: {
            return state;
        }
    }
};

export default settingsReducer;
