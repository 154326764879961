export const settingsConfig = {
    timezones: [
        { label: "Asia/Tokyo", value: "Asia/Tokyo" },
        { label: "Europe/Moscow", value: "Europe/Moscow" },
        { label: "Europe/Prague", value: "Europe/Prague" },
        { label: "UTC", value: "UTC" },
        { label: "America/New_York", value: "America/New_York" },
        { label: "America/Los_Angeles", value: "America/Los_Angeles" },
    ],
};

export default settingsConfig;
