// ** Redux Imports
import { combineReducers } from "redux";
// ** Reducers Imports
import { authReducer } from "./auth/authReducer";
import layout from "./layout";
import { settingsReducer } from "./settings/settingsReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    layout,
    settings: settingsReducer,
});

export default rootReducer;
