import { StateLogin } from "../../../interfaces";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

interface Action {
    type: string;
    payload: StateLogin | null;
}

export const authReducer = (state: StateLogin = { userRole: "", userName: "", secret: "" }, action: Action) => {
    switch (action.type) {
        case LOGIN: {
            return { ...state, ...action.payload };
        }
        case LOGOUT: {
            return { ...state, ...{ userRole: "", userName: "", secret: "" } };
        }
        default: {
            return state;
        }
    }
};

export default authReducer;
